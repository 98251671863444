import React, { useState, useRef, useEffect } from 'react';
import '../../styles/App.css';
import {
  searchDatabaseRoute,
  getPartialDataRoute,
  getTotalNodesCountRoute,
  getBasicUserDataRoute,
  fetchTagsRoute,
  getTagFilteredNodesRoute,
  getOnlyUsersNodesRoute
} from '../../utils/routes';
import Recursive from './Components/_recursive';
import Overview from './Components/_Overview';
import { Icons, LogMessageType, MainInterface, NodeData } from '../../functions_interfaces/interfaces';
import fetchData from '../../functions_interfaces/functions';
import axios from 'axios';
import DescriptionBox from './Components/_descriptionBox';
import UploadExcel from './Components/_UploadExcelPopup';
import TagSettingsComponent from './Components/tagComponent';
import { useTranslation } from 'react-i18next';
import { tag } from './Components/tagComponent';
import { IoFilter } from "react-icons/io5";
import MainManual from './Components/main.manuals';

function Main({ recursiveParent, 
                selectTable,
                actionLogReferences,
                DescriptionBoxReferences,
                OverViewReferences
            }: MainInterface) {
//----------------------------------- get data from backend -----------------------------------
const initData = {
  node_id: null,
  parent_node_id: null,
  ref_node_id: null,
  priority_number: 0,
  name: '',
  staff: [],
  path_info: '',
  description: '',
  status: '0',
  node_type: null,
  subNodes: [],
  tasks: [],
  divider: false,
  type_of_item: 'undefined',
  date_created: '**-**-****',
  date_finished: '**-**-****',
  date_submitted: '**-**-****',
  deadline: '**-**-****',
  file_name_submitted: '',
  sub_items: '',
  change_description: '',
  scale: '',
  class_id: '',
  section: '****',
  selected: false,
  listIndex: null,
  parent_node_name: '',
  tags: ''
};
    let [data, setData] = useState<NodeData[]>([initData])
    let [displayedData, setDisplayedData] = useState<NodeData[]>([initData])
    let usersData = useRef<any>([]);

    const [toggleInsertExcelVisibility, setToggleInsertExcelVisibility] = useState(false);

    const flatData = useRef<any>([]); // flattened main/master data structure
    const chosenArrayOfItemsToFilter = useRef<any>(flatData.current); // currently chosen list/array to be filtered
    const [filteredData, setFilteredData] = useState<NodeData[]>([]); // new filtered array depending on new keyword
    const [foundItemsCount, setFoundItemsCount] = useState(0); // count of found items based on keyword
    const [totalItemsCount, setTotalItemsCount] = useState(flatData.current.length)

    let keyWord = useRef(''); // currently inserted keyword
    let prevKeyWord = useRef(''); // previous keyword

    const [newKeyword, setNewKeyWord]= useState<string>('')
//----------------------------------- Show/Hide items on Main page  -----------------------------------
    const [allQuickInfo, showAllQuickInfo] = useState(true);
    const [moveItemsToggle, setMoveItemsToggle] = useState(false);
    const [unfoldTreeStructure, setUnfoldTreeStructure] = useState(false);
    const [isMounted, setIsMounted]= useState(false);
//----------------------------------- states of opened items -----------------------------------

    let openQuickInfoState = useRef<string[]>([]); // remembers state of opened quick infos
    let openFolderState = useRef<string[]>([]); //  remembers state of opened folders
    let prevOpenFolderState = useRef<any>([]);
    let activeUnfoldTreeButton = useRef(false);
//----------------------------------- Misc -----------------------------------
    const [update, forceUpdate] = useState(false); //forces rerender
    const [toggleOverview, setToggleOverview] = useState(false);
    const [toggleTagComponent, setToggleTagComponent] = useState<boolean>(false);
    const { t } = useTranslation();
    const [tags, setTags] = useState<any>();
    const [toggleTags, setToggleTags] = useState(false);

    const[toggleDropdown, setToggleDropdown] = useState<boolean>(false);
    const [toggleManual, setToggleManual] = useState<boolean>(false);

    const [selectedTags, setSelectedTags] = useState<tag[]>([]);

    let toggleUsersNodes = useRef(false);
    const [disableOverView, setDisableOverView] = useState<boolean>(true)
//------------------------------------descriptionBoxReferences => connection with descriptionBox component --------------------------------------

    DescriptionBoxReferences.usersData = usersData.current;
//-----------------------------------------------------------------------

    useEffect(() => {
      refreshData();
    }, []);


    OverViewReferences.disableButton = setDisableOverView;
//-----------------------------------------------------------------------
    async function getBasicUserData(): Promise<void> {
        try {
        actionLogReferences.updateLogString('Getting users', LogMessageType.Pending);
    
        const basicUsersData = await axios.get(getBasicUserDataRoute);
    
        console.log('Axios response here');
    
        if (basicUsersData && basicUsersData.data.resolved) {
            usersData.current = basicUsersData.data.data;
    
            actionLogReferences.updateLogString(
              'Data Transfer Success',
              LogMessageType.Success,
              JSON.stringify(basicUsersData.data, null, "\t")
            );
    
            console.log('Data Transfer Success');
        } else {
            actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(basicUsersData.data.message, null, "\t")
            );
        }
        } catch (e: any) {
        const msg = e.message + '\nCheck backend connection';
        alert(msg);
        actionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, msg);
        console.log(msg);
        }
    };

    async function fetchTags () {

      try {
        let response = await axios.get(fetchTagsRoute);
      
        if (response && response.data.resolved) {
            setTags(response.data.data);
        }
        else {
            actionLogReferences.updateLogString(
                'Error fetching data!',
                LogMessageType.Error,
                JSON.stringify(response.data.message, null, "\t")
            );
        }
      }
      catch (error) {
        actionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(error, null, "\t")
      );
      }
      
  };

    async function getTotalNodesCount(): Promise<void> {
      try {
        let response = await axios.post(getTotalNodesCountRoute, {tableName: selectTable});

        if (response && response.data.resolved) {
          setTotalItemsCount(response.data.data);
        }
        else {
          setTotalItemsCount(0);
          actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
      }
      catch (e: any) {
        const msg = e.message + '\nCheck backend connection';
        alert(msg);
        actionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, msg);
        console.log(msg);
      }
    };
//-----------------------------------------------------------------------
    async function setFetchedData(): Promise<void> {
        setIsMounted(false); // Toggle isMounted to indicate data transfer state
        
        try {
            actionLogReferences.updateLogString('Fetching data', LogMessageType.Pending);
        
            const newData = await fetchData(getPartialDataRoute, recursiveParent, selectTable);
            //const newData = await fetchData(routes.getTableRoute, recursiveParent, selectTable);
        
            console.log('axios response\n => NEW main data structure:\n', newData);
        
            if (newData && newData.resolved) {
            //data.current = newData.data; // Set the main data structure to received data
            console.log('new Data \n', newData.data);
            setData(newData.data);
            setDisplayedData(newData.data);
            await getTotalNodesCount();
        
            actionLogReferences.updateLogString(
                'Data Transfer Success',
                LogMessageType.Success,
                JSON.stringify(newData.data, null, "\t")
            );
        
            console.log('Data Transfer Success');
        
            setIsMounted(true);
            forceUpdate(!update);
            } else {
            actionLogReferences.updateLogString(
                'Error fetching data!',
                LogMessageType.Error,
                JSON.stringify(newData.message, null, "\t")
            );
            }
        } catch (e: any) {
            const msg = e.message + '\nCheck backend connection';
            alert(msg);
            actionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, msg);
            console.log(msg);
        }
        };

//-----------------------------------------------------------------------
    function closeAllOpenFolders(){
    // openFolderState is an array of item uuid's. when divider is clickend and if openFolderState is provided, it's id is saved 
    //in openFolderState. when user closes and opens parent divider the folders prev open remain open. 
    //function is used to clear openFolderState array and therefore close tree structure

        openFolderState.current = [];
        console.log('Open Folder State: ',openFolderState)
        setUnfoldTreeStructure(false);
        DescriptionBoxReferences.setShowDescriptionBox(false);
        activeUnfoldTreeButton.current = false;
        forceUpdate(!update);
    };
//-----------------------------------------------------------------------
    function openAllFolders(): void {
        // Function is currently in the making phase and has errors that need to be fixed
        // Its purpose is to unfold the whole tree structure with a button click
    
        setUnfoldTreeStructure(!unfoldTreeStructure);
        activeUnfoldTreeButton.current = !activeUnfoldTreeButton.current;
    };
//-----------------------------------------------------------------------

    async function searchDatabaseFilter() {
      setDisplayedData([]);
      try {
        let response = await axios.post(searchDatabaseRoute, {keywords: [newKeyword], language:'en'});
        setDisplayedData([]);
        if (response.data.resolved) {
          setDisplayedData(response.data.data);
          setFoundItemsCount(response.data.data.length);

          actionLogReferences.updateLogString(
            'Data Transfer Success',
            LogMessageType.Success,
            JSON.stringify(response.data.data, null, "\t")
            );
        }
        else {
          actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
        );
        }
      }
      catch (e: any) {

        const msg = e.message + '\nCheck backend connection';
        alert(msg);
        actionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, msg);
        console.log(msg);
      }
    };

    function setNewInsertForm () {
      /* function is called when "Add new item" is selected in ContexMenu. New item is created in
        DescriptionBox functional component. This function passes necessary data that needs to be
        inherited from parent node to new child.
        Function also toggles DescriptionBox (from regular to new item insert form)*/
    
/* 
        data.subNodes = data.subNodes?.filter(function(item) {
          return(item.node_id !== 'temporary_node');
        }) */
    
        let initItem: NodeData = {
          node_id: 'temporary_node',
          parent_node_id: recursiveParent,
          ref_node_id: null,
          priority_number: 0,
          name: Icons.warningLight.repeat(5) + 'NEW ITEM' + Icons.warningLight.repeat(5),
          staff: [],
          path_info: '',
          description: '',
          status: '',
          node_type: null,
          subNodes: [],
          tasks: [],
          divider: false,
          type_of_item: 'undefined',
          date_created: null, // PostgreSQL will handle the date creation
          date_finished: null,
          date_submitted: null,
          deadline: null,
          file_name_submitted: '',
          sub_items: '',
          change_description: '',
          scale: '',
          class_id: '',
          section: '',
          selected: false,
          listIndex: null,
          tags: ''
        };
        let newNodeArray: NodeData[] = [...displayedData, initItem];

        setDisplayedData(newNodeArray);
      };


    function searchFilter(arrayToFilter , insertedKeyword: string) {
    keyWord.current = insertedKeyword.toLowerCase();
    
    if (keyWord.current === '') {
        openFolderState.current = prevOpenFolderState.current;
        forceUpdate(!update);
        return;
    }
    
    const filteredData = arrayToFilter.filter((item) => {
        const lowercaseName = item.name.toLowerCase();
        const lowercaseDescription = item.description?.toLowerCase() || '';
        const lowercaseTypeOfItem = item.type_of_item?.toLowerCase() || '';
        const lowercaseSectionOfItem = String(item.section)?.toLowerCase() || '';
    
        return (
        lowercaseName.includes(keyWord.current) ||
        lowercaseDescription.includes(keyWord.current) ||
        lowercaseTypeOfItem.includes(keyWord.current) ||
        lowercaseSectionOfItem.includes(keyWord.current)
        );
    });
    
    const uniqueFilteredData: any = [...new Set(filteredData)];
    setFoundItemsCount(uniqueFilteredData.length);
    setFilteredData(uniqueFilteredData);
    };
//-----------------------------------------------------------------------
    function searchBarKeybordButtons(e): void {
        // Function is used to apply selected keyboard keys to cause actions
        // => ESC and DEL => clear the search bar and search results
        // => ENTER => saves current keyword and sets search bar for a new one => used for inserting multiple keywords => ("predo => 2d => section => name")
    
        const keyCode = e.keyCode;
    
        if (keyCode === 46 || keyCode === 27) {
        // 46 => keyCode for ESC
        // 27 => keyCode for DEL
    
          setFoundItemsCount(0);
          setDisplayedData(data);
          setNewKeyWord('');
        }
    
        if (keyCode === 13) {
          searchDatabaseFilter();
        }
    };

    async function getOnlyUsersNodes() {
      try {
        let response = await axios.get(getOnlyUsersNodesRoute);

        if (response && response.data.resolved) {
          setDisplayedData(response.data.data);
        }
        else {
          actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
      }
      catch (error) {
        actionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(error, null, "\t")
        );
      }
    }

    async function toggleToUsersNodes () {
      toggleUsersNodes.current = !toggleUsersNodes.current;
      toggleUsersNodes.current? await getOnlyUsersNodes() : setDisplayedData(data);
    }

    async function setFilteredItemsBasedOnTags () {

      try {
        let response = await axios.put(getTagFilteredNodesRoute, {tags: selectedTags});
        
        if (response && response.data.resolved) {
          setDisplayedData(response.data.data);
          setFoundItemsCount(response.data.data.length);
        }
        else {
          actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
      }
      catch (e) {
        actionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(e, null, "\t")
        );
      }
    }

    

  async function refreshData() {
    try {
      await setFetchedData();
      await getBasicUserData();
      await fetchTags();
    }
    catch (e) {
      actionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(e, null, "\t")
      );
    }
  }
  function toggleTag (tag: tag) {
    if (selectedTags.includes(tag)) {
        setSelectedTags(selectedTags.filter(t => t.id !== tag.id));
    } else {
        setSelectedTags([...selectedTags, tag]);
    }
};

useEffect(() => {
  if (selectedTags.length > 0) {
    setFilteredItemsBasedOnTags();
  }
  else {
    setDisplayedData(data);
  }
}, [selectedTags])

    function DropdownMenu({ t, closeAllOpenFolders, openAllFolders, setMoveItemsToggle}) {

      return (
        <div className="dropdown">
          {/* Main button */}
          <button className = 'globals--regular-button' onClick={() => {setToggleDropdown(!toggleDropdown)}}>
            {t('main.buttonbar.menu')}
          </button>

          {toggleDropdown &&
            <div className="dropdown-content">
              
              <button className = 'globals--regular-button' onClick={() => { closeAllOpenFolders(); }}>
                {t('main.buttonbar.closeStructure')}
              </button>

  {/*             <button onClick={() => { openAllFolders(); }}>
                {t('main.buttonbar.unfoldTree')}
              </button> */}
              <button className = 'globals--regular-button' onClick={() => setToggleInsertExcelVisibility(true)}>
                {t('main.buttonbar.importX')}
              </button>
            </div>
            }
        </div>
      );
    }

    
//-----------------------------------------------------------------------
//-----------------------------------------------------------------------
return (
    <div className='main-component-wrapper'>

      <header className='globals--module-navbar'>

        <div className = 'globals--module-navbar-title'>
          {t(`index.head.dropdown.main`)}
        </div>

        <div className = 'helpers--section-container-orientation-flex-row'>
            <input
              type='text'
              placeholder= {t('main.buttonbar.search')}
              value={newKeyword}
              onChange={(e) => setNewKeyWord(e.target.value.toLowerCase())}
              onKeyDown={(e) => searchBarKeybordButtons(e)}
              
            />
            <button className = 'globals--regular-button'
                    onClick={() => searchDatabaseFilter()}>
              Search
            </button>

            <button className = 'globals--regular-button' onClick={() => setToggleTags(!toggleTags)}>
              <IoFilter />
            </button>

            {newKeyword !== '' ? (
              <div className = 'globals--search-bar-info-badge'>
                {prevKeyWord.current}&nbsp;&nbsp;Found {foundItemsCount}
              </div>
            ) : (
              <>
              </>
            )}

            <div className = 'globals--search-bar-info-badge'>
              {totalItemsCount}
            </div>
        </div>

        <div className = 'helpers--section-container-orientation-flex-row'>

          <button className = 'globals--regular-button' 
                    onClick={() => setToggleManual(!toggleManual)}>
              Navodila
          </button>
          <button className = 'globals--regular-button' 
                  onClick={() => setToggleTagComponent(!toggleTagComponent)}>
            Tags
          </button>

          <button className = {toggleUsersNodes.current? 'globals--red-button' : `globals--regular-button`} onClick={() => toggleToUsersNodes()} >
            {t('main.buttonbar.my_nodes')}
          </button>

          <DropdownMenu t = {t}
                        closeAllOpenFolders={closeAllOpenFolders}
                        openAllFolders={openAllFolders}
                        setMoveItemsToggle={setMoveItemsToggle}/>

          <button className = 'globals--regular-button' 
                  onClick={() => { setMoveItemsToggle(!moveItemsToggle)}}>
              {t('main.buttonbar.moveItems')}
          </button>

          <button className = 'globals--regular-button' 
                  onClick={() => setToggleOverview(!toggleOverview)} 
                  disabled = {disableOverView}
                  style={{ backgroundColor: toggleOverview ? 'red' : ''}}>
            {t('main.buttonbar.overview')}
          </button>
          
          <button className = 'globals--regular-button' onClick={() => refreshData()}>
            {t('main.buttonbar.refresh')}
          </button>

        </div>
      </header>

      {toggleTagComponent &&
        <TagSettingsComponent actionLogReferences={actionLogReferences} 
                              toggleTagComponent={setToggleTagComponent}
                              tagsArray = {tags}
                              fetchTags={fetchTags}/>
      }
      {toggleManual &&
        <MainManual/>
      }

      {toggleTags &&
        <div className = 'resizable-container'>
        <div className = 'tag-filter-container'>
          <div className='tagsContainer'>
              {tags?.tags
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(tag => (
                  <button
                      key={tag.id}
                      className='tagItem'
                      style = {{backgroundColor: selectedTags.includes(tag)? '#1a73e8': '', color: selectedTags.includes(tag)?'white': ''}}
                      onClick={() => toggleTag(tag)}
                  >
                      {tag.name}
                  </button>
              ))}
          </div>
        </div>
      </div>
      }

      <main className='main-component-wrapper-main-content'>
          {isMounted ? (
            <div className = 'main-component-wrapper-main-content-recursive-containerHelper'>
                {!toggleOverview && 
                  <>
                    <button className="addNewButton" onClick={() => setNewInsertForm()}>
                      +  Add New
                    </button>
                    {displayedData.map((item, index) => {
                      return (
                        <div key = {item.node_id}>
                          <Recursive
                              data={item}
                              selectedTags = {selectedTags}
                              setNewData={setFetchedData}
                              fetchNewParentData={setFetchedData}
                              DescriptionBoxReferences={DescriptionBoxReferences}
                              actionLogReferences={actionLogReferences}
                              openFolderState={openFolderState}
                              openQuickInfoState={openQuickInfoState}
                              moveItemsToggle={moveItemsToggle}
                              forceUpdate={update}
                              showAllQuickInfo={allQuickInfo}
                              selectedTable={selectTable}
                              unfoldTree={unfoldTreeStructure}
                              OverViewReferences={OverViewReferences}
                            />
                        </div>
                      )
                    })}
                  </>}
            </div>
          ) : (
            <>
            </>
          )}
          {toggleOverview && 
            <Overview
                OverViewReferences={OverViewReferences}
                DescriptionBoxReferences={DescriptionBoxReferences}
                actionLogReferences={actionLogReferences}
              />}
          
          <div className = 'descriptionBoxContainer'>
              <DescriptionBox
                    DescriptionBoxReferences={DescriptionBoxReferences}
                    tableName={selectTable}
                    actionLogReferences={actionLogReferences}
                  />
          </div>
      </main>
      

       <UploadExcel OverViewReferences={OverViewReferences} 
                    actionLogReferences= {actionLogReferences} 
                    DescriptionBoxReferences = {DescriptionBoxReferences}
                    toggleInsertExcelVisibility={toggleInsertExcelVisibility}
                    setToggleInsertExcelVisibility={setToggleInsertExcelVisibility}/>
    </div>
  );  
};


export default Main