import { useState, useRef } from "react";
import axios from "axios";
import { 
    uploadExcelRoute ,
    confirmAndInsertPreviewDataRoute,
    deletePreviewDataRoute
} from "../../../utils/routes";
import Recursive from "./_recursive";
import { LogMessageType } from "../../../functions_interfaces/interfaces";

function UploadExcel ({ OverViewReferences, actionLogReferences, DescriptionBoxReferences, toggleInsertExcelVisibility, setToggleInsertExcelVisibility}) {

    let excelDataTemplate = {
      cellRange: '',
      sheetNumber:'',
      parentNode:''
    }
  
    const isOpen: any = 'null'
    const [excelData, setExcelData] = useState(excelDataTemplate);
    const [previewData, setPreviewData] = useState(null);
    const [excelPreviewFileName, setExcelPreviewFileName] = useState('');
  
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [confirmPreviewData, setConfirmPreviewData] = useState(false);
  
    const openFolderState = useRef([])
    const openQuickInfoState = useRef([]);
  
  
    const handleConfirmButtonClick = async () => {
  
      if (!selectedFile) return;
  
      try {
        
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('cellRange', excelData.cellRange);
        formData.append('sheetNumber', excelData.sheetNumber);
        formData.append('parentNode', excelData.parentNode);
        let response = await axios.post(uploadExcelRoute, formData);
  
        if (response && response.data.resolved) {
          setPreviewData(response.data.excelPreview.data);
          setExcelPreviewFileName(response.data.excelPreview.fileName);
          setConfirmPreviewData(true)
          console.log(response)
        }
        else {
          setPreviewData(null);
          setConfirmPreviewData(false);

          actionLogReferences.updateLogString(
            'Failed to process excel',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
        );
        };
      }
      catch (error) {
          actionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(error, null, "\t")
          );
          setConfirmPreviewData(false)
      }
    };
  
    const selectFileToUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0] || null;
      setSelectedFile(selectedFile);
    };
  
    const checkIfDataIsSet = (): boolean => {
      if (excelData.parentNode === '') {
        return true;
      }
      if (excelData.cellRange === '') {
        return true;
      }
      if (excelData.sheetNumber === '') {
        return true;
      }
      else if (selectedFile === null) {
        return true;
      }
      return false
    }
    function initUploadData () {
      setExcelData(excelDataTemplate);
      setPreviewData(null);
      setExcelPreviewFileName('');
      setSelectedFile(null);
      setConfirmPreviewData(false);
    }
  
    const confirmAndInsertPreviewData = async () => {
      try {
        let response = await axios.put(confirmAndInsertPreviewDataRoute, {excelPreviewFileName});
  
        if (response && response.data.resolved) {
  
          initUploadData();
          setToggleInsertExcelVisibility(false);
  
          actionLogReferences.updateLogString(
              'Excel successfuly inserted',
              LogMessageType.Success,
              JSON.stringify(response.data.data, null, "\t")
          );
        }
        else {
          actionLogReferences.updateLogString(
              'Backend error inserting excel data',
              LogMessageType.Error,
              JSON.stringify(response.data.message, null, "\t")
          );
        }
      }
      catch (e) {
        actionLogReferences.updateLogString(
            'Frontend error inserting excel data',
            LogMessageType.Error,
            JSON.stringify(e, null, "\t")
        );
      };
    };
  
    const deletePreviewDataAndClose = async () =>  {
      try {
        if (confirmPreviewData) {
          let response = await axios.put(deletePreviewDataRoute, {excelPreviewFileName});
  
          if (response && response.data.resolved) {
            initUploadData();
            setToggleInsertExcelVisibility(false);
          }
          else {
            actionLogReferences.updateLogString(
                'Backend error deleting excel data',
                LogMessageType.Error,
                JSON.stringify(response.data.message, null, "\t")
            );
          }
        }
  
        else {
          initUploadData();
          setToggleInsertExcelVisibility(false);
        }
      }
      catch (e) {
        actionLogReferences.updateLogString(
            'Frontend error deleting excel data',
            LogMessageType.Error,
            JSON.stringify(e, null, "\t")
        );
      }
    };
  
  
    if (!isOpen) return null;
  
    return (
      <div style = {{display: toggleInsertExcelVisibility?'': 'none'}}>
      <div className='text-area-background-shade' style = {{display:previewData?'': 'none' }}>
  
      </div>
      <div className={`text-area-overlay ${previewData?'data-display': ''}`}>
        {/* Close Button */}
        <button className="close-button" onClick={deletePreviewDataAndClose}>
          &times;
        </button>
  
        <div className={`button-section ${previewData?'data-display-buttons': ''}`}>

            <label htmlFor="excel-range">Parent Node Id:</label>
            <input
              type="text"
              id="excel-range"
              placeholder="Parent Id [e.g., 99c5228e-3550-4efc-bba0-aab6ca875022]"
              value={excelData.parentNode}
              onChange={(e) => setExcelData(prev => ({...prev, parentNode: e.target.value}))}
            />
            <label htmlFor="excel-range">Excel Range:</label>
            <input
              type="text"
              id="excel-range"
              placeholder="Excel Range [e.g., A10:L400]"
              value={excelData.cellRange}
              onChange={(e) => setExcelData(prev => ({...prev, cellRange: e.target.value}))}
            />
  
            <label htmlFor="excel-range">Sheet Index:</label>
            <input
              type="text"
              id="excel-range"
              placeholder="Sheet Number [e.g., 0]"
              value={excelData.sheetNumber}
              onChange={(e) => setExcelData(prev => ({...prev, sheetNumber: e.target.value}))}
            />

            <label htmlFor="excel-range">Import excel: </label>
            <input
              type="file"
              id="excel-file"
              accept=".xlsx, .xls, .xlsm"
              onChange={selectFileToUploadHandler}
              className = 'globals--regular-button'
            />
            
        </div>
  
  
  
        {/* Excel Preview */}
        <div className="excel-preview" style = {{display: previewData? '' : 'none'}}>
          {previewData &&
            <Recursive
              data={previewData}
              setNewData={setPreviewData}
              DescriptionBoxReferences={DescriptionBoxReferences}
              actionLogReferences={actionLogReferences}
              openFolderState={openFolderState}
              openQuickInfoState={openQuickInfoState}
              moveItemsToggle={false}
              forceUpdate={false}
              showAllQuickInfo={true}
              selectedTable={"ProjectTable0"}
              unfoldTree={true}
              OverViewReferences={OverViewReferences}
            />
          }
        
        </div>
  
        {confirmPreviewData? 
        
        <div >
          <button
              onClick={confirmAndInsertPreviewData}
              disabled = {!confirmPreviewData}
              className = 'globals--green-button'
            >
            Confirm Upload
          </button>

          <button
              onClick={deletePreviewDataAndClose}
              className = 'globals--red-button'
            >
            Cancel Upload
          </button>

          <button
            onClick={handleConfirmButtonClick}
            disabled = {checkIfDataIsSet()}
            className = 'globals--regular-button'
          >
            Reprocess
          </button>

        </div>

        :

        <button
          onClick={handleConfirmButtonClick}
          disabled = {checkIfDataIsSet()}
          className = 'globals--regular-button'
        >
          Preview Upload
        </button>
      }
        
  
        
      </div>
    </div>
    )
  }

  export default UploadExcel;