import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {    editBannerTextRoute, 
            getBannerTextRoute, 
            getBasicUserDataRoute, 
            getDeparturesRoute, 
            insertDepartureRoute, 
            updateDeparturesRoute
        } from '../../utils/routes';
import axios from 'axios';
import { ActionLogReferences, LogMessageType } from '../../functions_interfaces/interfaces'

interface departure_project {
  project_uuid: string;
  project_name: string;
  project_deadline: Date;
  project_tasks: project_task[];
  project_status?: number;
};

interface userOnTask {
  user_uuid: string,
  firstname: string,
  lastname: string
};

interface project_task {
  task_name: string,
  task_uuid: string,
  status: number,
  users_on_task: userOnTask[],
  project_uuid?: string
};

const  initDepartureProjectData = {
    project_uuid: '',
    project_name: '',
    project_deadline: new Date(),
    project_tasks: [],
}

const Dashboard = ({actionLogReferences, PageAccess}) => {
    const projects: departure_project[] = [];
    
    
    const [departures, setDepartures] = useState<departure_project[]>(projects);
    const [openedDepartures, setOpenedDepartures] = useState<string[]>([]);
    const [bannerText, setBannerText] = useState<string>('');
    const [toggleEditBannerText, setToggleEditBannerText] = useState<boolean>(false);
    const [hideEdit, setHideEdit] = useState<boolean>(false);
    const [visibleProjectIndex, setVisibleProjectIndex] = useState(0);
    const [showProjectsOverview, setShowProjectsOverview] = useState<boolean>(true);

    useEffect(()=> {
        getDepartures();
        getBannerText();
        checkUserPageAccesType();
    }, []);

    useEffect(() => {
        const projectInterval = setInterval(() => {
            setVisibleProjectIndex(prevIndex => (prevIndex + 2) % departures.length);
        }, 30000);

        return () => clearInterval(projectInterval);
    }, [departures.length]);

    useEffect(() => {
      const intervalDuration = 90000; 
      const activeDuration = 20000; 
    
      if (!hideEdit) {
        const projectInterval = setInterval(() => {
          
          if (hideEdit)
            setShowProjectsOverview(false);
      
          const timeout = setTimeout(() => {

              setShowProjectsOverview(true);
          }, activeDuration);
      
          return () => clearTimeout(timeout);
        }, intervalDuration);
      
        return () => clearInterval(projectInterval);
      }
      
    }, [showProjectsOverview]);
    
    function checkUserPageAccesType () {
        if (PageAccess) {
            const userAccess = PageAccess.find(item => item.page_name === 'DepartureBoard');
            setHideEdit(userAccess.allowed_access.edit);
        }
    };
    const handleFrontendTransactionError = (error_message) => {
        return (
            actionLogReferences.updateLogString (
                'Frontend Data Transfer Error',
                LogMessageType.Error,
                JSON.stringify(error_message, null, "\t")
            )
        )
    };
    const handleBackendTransationError = (error_message) => {
        return (
            actionLogReferences.updateLogString(
                'Data Transfer Error',
                LogMessageType.Error,
                JSON.stringify(error_message, null, "\t")
            )
        )
    };
    const handleTransationOK = (message) => {
        return (
           actionLogReferences.updateLogString(
                'Data Transfer Success',
                LogMessageType.Success,
                JSON.stringify(message, null, "\t")
            )
        )
    };

    function setDeparturesStatus(departures: departure_project[]): void {
      for (let departure of departures) {
        departure.project_status = (departure.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / departure.project_tasks?.length || 0)
      }

      setDepartures(departures);
    }

    async function getDepartures (): Promise<void>{
        try {
            let response = await axios.get(getDeparturesRoute); 

            if (response && response.data.resolved ) {

                setDeparturesStatus(response.data.data);

                handleTransationOK(response.data.message);
            }
            else {
                handleBackendTransationError(response.data.message);
            }
        } catch (error) {
            handleFrontendTransactionError(error)
        }
    }

    async function getBannerText () {
        try {
            let response = await axios.get(getBannerTextRoute);

            if (response && response.data.resolved) {
                setBannerText(response.data.data);

                handleTransationOK(response.data.message);
            }
            else {
                handleBackendTransationError(response.data.message);
            }
            
        } catch (error) {
            handleFrontendTransactionError(error)
        }
    }

    async function deleteDeparture ( departureUuid, departureName ): Promise<void> {

        if (window.confirm(`Ali Resnično želiš izbrisati projekt: ${departureName}`)) {
            try {
                let response = await axios.delete(getDeparturesRoute, {data: {project_uuid: departureUuid}});
    
                if (response && response.data.resolved) {
                    await getDepartures();

                    handleTransationOK(response.data.message);
                }
                else {
                    handleBackendTransationError(response.data.message);
                }
            } catch (error) {
                handleFrontendTransactionError(error)
            }
        }
    }

    function setTaskColor(taskStatus: number) {
      if (taskStatus < 51) {
        return 'bg-danger';
      } else if (taskStatus < 90) {
        return 'bg-warning';
      } else {
        return 'bg-success';
      }
    }

    const handleSubmitBannerText = async(): Promise<void> => {
        try {
            let response = await axios.put(editBannerTextRoute, {bannerText})

            if (response && response.data.resolved ) {
                setBannerText(response.data.data);
                handleTransationOK(response.data.message);

                setToggleEditBannerText(false);
            }
            else {
                handleBackendTransationError(response.data.message);
            }

        } catch (error) {
            handleFrontendTransactionError(error)
        }
    }

    const toggleFormAndCard = (project_uuid) => {
        if (openedDepartures.includes(project_uuid)) {
            const filteredDepartures = openedDepartures.filter((item) => item !== project_uuid);
            setOpenedDepartures([...filteredDepartures]);
        }
        else {
            const filteredDepartures = openedDepartures;
            filteredDepartures.push(project_uuid);
            setOpenedDepartures([...openedDepartures, project_uuid]);
        }
    }

    return (
      <>
<div className='dashboard-main' style={{ overflowX: 'hidden', overflowY: hideEdit ? 'auto' : 'hidden', height: hideEdit ? '' : '100vh', maxWidth: '100vw' }}>
  {showProjectsOverview?
  <>
  
  {toggleEditBannerText ? (
        <div className='full-width'>
          <textarea className='full-width no-resize' value={bannerText} onChange={(e) => setBannerText(e.target.value)}></textarea>
          <button className="globals--green-button" onClick={handleSubmitBannerText}>
            Update Banner
          </button>
        </div>
      ) : (
        <div className='banner-container'>
          <div className="banner banner-font"><span className='rotated-span'>{'\u{1F6E7}'} </span>{bannerText}</div>
          {hideEdit && (
            <button className="globals--green-button" onClick={() => setToggleEditBannerText(!toggleEditBannerText)}>
              Edit banner text
            </button>
          )}
        </div>
      )}
      

      <div className="dashboard-container full-width">
        {hideEdit && (
          <div className='departure-form-content'>
            <DepartureForm actionLogReferences={actionLogReferences} getDepartures={getDepartures} updateForm={false} />
          </div>
        )}

        {departures.slice(hideEdit ? 0 : visibleProjectIndex, hideEdit ? departures.length : visibleProjectIndex + 2).map((project, pIndex) => (
          <div key={pIndex} className='full-width flex-grow'>
            {openedDepartures.includes(project.project_uuid) ? (
              <div className='departure-form-content'>
                <DepartureForm key={pIndex} actionLogReferences={actionLogReferences} getDepartures={getDepartures} initDepartureProject={project} updateForm={true} />
                <div className='departure-form-content-head'>
                  <button className='globals--transparent-button flex-grow white-border' onClick={() => toggleFormAndCard(project.project_uuid)}>
                    Cancel Edit
                  </button>
                  <button className='globals--transparent-button flex-grow flex-end white-border' onClick={() => deleteDeparture(project.project_uuid, project.project_name)}>
                    Delete Project
                  </button>
                </div>
              </div>
            ) : (
              <div key={pIndex} className="project-card">
                <div className='column-gap'>
                  <div className='row-space-between'>
                    <h1 className='zero-margin bold white-text'>{project.project_name}</h1>
                    <h2 className='flex-column text-end bold no-wrap'>
                        {String(project.project_deadline).split('T')[0]}
                    </h2>
                  </div>
                  <div className={`progress full-width ${hideEdit ? '' : 'small-progress'}`}>
                    <div className={`progress-bar progress-bar-striped bg-success progress-bar-animated ${setTaskColor(Number((project.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / project.project_tasks?.length || 0).toFixed(0)))}`} role="progressbar" style={{  width: `${project.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / project.project_tasks?.length || 0}%` }} aria-valuenow={project.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / project.project_tasks?.length || 0} aria-valuemin={0} aria-valuemax={100}>
                      {(project.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / project.project_tasks?.length || 0).toFixed(0)}%
                    </div>
                  </div>
                </div>
                <div className='row-max-width'>
                  <div className='column full-width'>
                    {project.project_tasks?.map((task, mIndex) => (
                      <div key={mIndex} className="member-details">
                        <strong className={hideEdit ? '' : 'large-font flex-grow'}>{task.task_name}</strong>
                        <div className='banner-section flex-3'>
                          <ul className='moving-section'>
                            {task.users_on_task?.map((user, tIndex) => (
                              <>
                                <li key={tIndex} 
                                    className='inline margin-x large-font'>
                                      {user.firstname + ' ' + user.lastname}
                                      {tIndex < task.users_on_task.length - 1 ? ' -' : ''}
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                        <div className={`progress half-width ${hideEdit ? '' : 'tall-progress'}`}>
                          <div className={`progress-bar progress-bar-striped progress-bar-animated ${setTaskColor(task.status)}`} 
                                role="progressbar" 
                                style={{ width: `${task.status}%` }} 
                                aria-valuenow={task.status} 
                                aria-valuemin={0} 
                                aria-valuemax={100}>
                            {`${task.status}%`}
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
                {hideEdit && (
                  <div className='row-gap'>
                    <button className='globals--green-button flex-grow' onClick={() => toggleFormAndCard(project.project_uuid)}>
                      Edit
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      </>
      :
      <ProjectsList projects = {departures}
                  hideEdit = {hideEdit}
                  setTaskColor={setTaskColor}
                  />
      }
    </div>
  
    </>
    );
};

interface ProjectListInterface {
  projects: any[];
  hideEdit: boolean;
  setTaskColor: any;
};

const ProjectsList: React.FC<ProjectListInterface> = ({projects, hideEdit, setTaskColor}) => {
  return (
<div className="dashboard-container full-width" style = {{padding: 0, margin: '0', border: 'none'}}>
  <div className='full-width flex-grow'>
    <div className="project-card" style = {{padding: '0 10px', border: 'none'}}>
      <div className='row-max-width'>
        <div className='column full-width'>
        {projects
          ?.sort((a, b) => b.project_status - a.project_status)
          .map((project, mIndex) => (
            <div key={mIndex} className="member-details">
              <strong className={hideEdit ? '' : 'large-font flex-grow'}>{project.project_name}</strong>
              <div className={`progress half-width ${hideEdit ? '' : 'tall-progress'}`} style = {{minWidth:'30%', maxWidth:'30%', background: 'rgba(128, 128, 128, 0.6)'}}>
                <div className={`progress-bar progress-bar-striped progress-bar-animated ${setTaskColor(project.project_status)}`} 
                      role="progressbar" 
                      style={{ width: `${project.project_status}%` }} 
                      aria-valuenow={project.project_status} 
                      aria-valuemin={0} 
                      aria-valuemax={100}>
                  {project.project_status.toFixed(0)}%
                </div>
              </div>
            </div>
          ))}
          </div>
      </div>
    </div>
  </div>
</div>
  )
};



interface departureFormProps {
    actionLogReferences: ActionLogReferences,
    getDepartures: () => void,
    initDepartureProject?: departure_project,
    updateForm?: boolean
};


export const DepartureForm: React.FC<departureFormProps> = ({actionLogReferences, getDepartures, initDepartureProject, updateForm }) => {

  const [formData, setFormData] = useState<departure_project>(initDepartureProject? initDepartureProject : initDepartureProjectData);
  const [currentlyViewedTask, setCurrentlyViewedTask] = useState<project_task | null>(null);
  const [taskIndex, setTaskIndex] = useState<number>(0);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
      getUsersData();
    }, []);
    

  const handleSubmit = async(event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (updateForm && updateForm === true) {
        await updateDeparture();
      }
      else {
        await createNewDeparture();
      }
      getDepartures();
  };

  const handleFrontendTransactionError = (error_message) => {
    return (
            actionLogReferences.updateLogString (
                'Frontend Data Transfer Error',
                LogMessageType.Error,
                JSON.stringify(error_message, null, "\t")
            )
        )
    }
    const handleBackendTransationError = (error_message) => {
        return (
            actionLogReferences.updateLogString(
                'Data Transfer Error',
                LogMessageType.Error,
                JSON.stringify(error_message, null, "\t")
            )
        )
    }
    const handleTransationOK = (message) => {
        actionLogReferences.updateLogString(
            'Data Transfer Success',
            LogMessageType.Success,
            JSON.stringify(message, null, "\t")
        );
    }

  async function updateDeparture (): Promise<void> {
    try {
        let response = await axios.put(updateDeparturesRoute, formData);

        if (response && response.data.resolved) {
            handleTransationOK(response.data.message);
        } else {
            handleBackendTransationError(response.data.message);
        }
    } catch (error) {
        handleFrontendTransactionError(error)
    }
}

  const handleInputChange = (
      event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      taskIndex?: number,
      userIndex?: number
    ) => {
      const { name, value } = event.target;
  
      if (typeof taskIndex === 'number' && typeof userIndex === 'number') {
        const updatedTasks = [...formData.project_tasks];
        updatedTasks[taskIndex].users_on_task[userIndex] = {
          ...updatedTasks[taskIndex].users_on_task[userIndex],
          [name]: value,
        };
        setFormData({ ...formData, project_tasks: updatedTasks });
      } else if (typeof taskIndex === 'number') {
        const updatedTasks = [...formData.project_tasks];
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          [name]: value,
        };
        setFormData({ ...formData, project_tasks: updatedTasks });
  
        if (currentlyViewedTask) {
          setCurrentlyViewedTask(updatedTasks[taskIndex]);
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };

    const handleAddTask = () => {
      setFormData({
        ...formData,
        project_tasks: [
          ...formData.project_tasks,
          { task_name: 'NEW TASK', task_uuid: '', status: 0, users_on_task: [] },
        ],
      });
    };

    const handleAddUser = (taskIndex: number, user: userOnTask) => {
      const updatedTasks = formData.project_tasks;
      console.log(updatedTasks);
      updatedTasks[taskIndex].users_on_task.push(user);
      setFormData({ ...formData, project_tasks: updatedTasks });
  
      if (currentlyViewedTask) {
        setCurrentlyViewedTask(updatedTasks[taskIndex]);
      }
    };
  
    const handleRemoveUser = (taskIndex: number, userIndex: number) => {
      const updatedTasks = [...formData.project_tasks];
      updatedTasks[taskIndex].users_on_task.splice(userIndex, 1);
      setFormData({ ...formData, project_tasks: updatedTasks });
  
      if (currentlyViewedTask) {
        setCurrentlyViewedTask(updatedTasks[taskIndex]);
      }
    };
  
    const handleUserSelect = (event: ChangeEvent<HTMLSelectElement>, taskIndex: number) => {
      const userUuid = event.target.value;
      const selectedUser = usersData.find((user: any) => user.useruuid === userUuid);
      if (selectedUser) {
        handleAddUser(taskIndex, selectedUser);
      }
      event.target.value = '';
    };

    async function getUsersData(): Promise<void> {
      try {
        const response = await axios.get(getBasicUserDataRoute);
  
        if (response && response.data.resolved) {
            setUsersData(response.data.data);
            handleTransationOK(response.data.message);
        } else {
            handleBackendTransationError(response.data.message);
        }
      } catch (error) {
            handleFrontendTransactionError(error)
      }
    };

    const handleDeleteTask = (): void => {
        formData.project_tasks.splice(taskIndex, 1);
        setFormData({...formData});
    }

    async function createNewDeparture (): Promise<void> {
      try {
        let response = await axios.post(insertDepartureRoute, formData);

        if (response && response.data.resolved) {
            setFormData(initDepartureProjectData);
            setCurrentlyViewedTask(null);

            handleTransationOK(response.data.message);
        }
        else {
            handleBackendTransationError(response.data.message);
        }
      }
      catch (error) {
        handleFrontendTransactionError(error)
      }
    }

  
  return (
      <form onSubmit={handleSubmit} className = 'departure-form-container'>
          
              <div className = 'departure-form-content-head'>
                  <div>
                      <label>
                          <input
                              type="text"
                              name="project_name"
                              placeholder="Project Name"
                              value={formData.project_name}
                              onChange={handleInputChange}
                          />
                      </label>
                      <h3 className = 'departure-form-title'>{formData.project_name}</h3>
                  </div>
                    <div className='progress' style = {{width: '50%'}}>
                        <div className="progress-bar progress-bar-striped bg-success" 
                            role="progressbar" 
                            style={{ width: `${formData.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / formData.project_tasks?.length || 0}%` }} 
                            aria-valuenow={formData.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / formData.project_tasks?.length || 0}
                            aria-valuemin={0} 
                            aria-valuemax={100}>
                        {(formData.project_tasks?.map((item) => item.status).reduce((acc, status) => acc + status, 0) / formData.project_tasks?.length || 0).toFixed(2)}
                        </div>
                    </div>
                  <label>
                        <input
                        type = "date"
                        name = "project_deadline"
                        value = {String(formData.project_deadline)}
                        onChange = {(e) => handleInputChange(e)}
                        />
                    </label>
              </div>

              <div className = 'departure-form-content-body'>
              {formData.project_tasks?.map((task, mIndex) => (
                  <div key={mIndex} className="member-details">
                      <strong onClick={() => { setCurrentlyViewedTask(task); setTaskIndex(mIndex); }}>{task.task_name}</strong>
                      <div className='banner-section'>
                          <ul className='moving-section'>
                              {task.users_on_task?.map((user, tIndex) => (
                                  <li key={tIndex}>{user.firstname + ' ' + user.lastname}</li>
                              ))}
                          </ul>
                      </div>
                      <div className = 'departure-form-content-body-status-container'>
                          <div className='progress' style = {{width: '100%'}}>
                              <div className="progress-bar progress-bar-striped" 
                                  role="progressbar" 
                                  style={{ width: `${task.status}%` }} 
                                  aria-valuenow={25} 
                                  aria-valuemin={0} 
                                  aria-valuemax={100}>
                              {task.status}%
                              </div>
                          </div>
                      </div>
                  </div>
              ))}
              {currentlyViewedTask && (
                  <div key={currentlyViewedTask.task_uuid} style = {{display:'flex', flexDirection:'row'}}>
                      <div style = {{display: 'flex', flexDirection:'column', justifyContent:'space-between', flex:'1'}}>
                        <label>
                            Task Name:
                            <input
                            type="text"
                            name="task_name"
                            value={currentlyViewedTask.task_name}
                            onChange={(e) => handleInputChange(e, taskIndex)}
                            />
                        </label>
                        <label>
                            Status:
                            <input
                            type="number"
                            name="status"
                            value={currentlyViewedTask.status}
                            onChange={(e) => handleInputChange(e, taskIndex)}
                            />
                        </label>
                        <label>
                            Select Users:
                            <select onChange={(e) => handleUserSelect(e, taskIndex)} defaultValue="">
                                <option value="" disabled>Select a user</option>
                                {usersData?.map((user: any) => (
                                <option key={user.useruuid} value={user.useruuid}>
                                    {user.firstname + ' ' + user.lastname}
                                </option>
                                ))}
                            </select>
                        </label>
                        <button className='globals--red-button' onClick = {handleDeleteTask}>Delete Task</button>
                      </div>
                      <div style = {{flex:'1', maxHeight:'100%', overflow:'auto'}}>
                        {currentlyViewedTask.users_on_task?.map((user, userIndex) => (
                            <div key={user.user_uuid} 
                                onClick = {() => handleRemoveUser(taskIndex, userIndex)} 
                                className = 'departure-form-content-body-users'>
                              	{ user.firstname + ' ' + user.lastname}
                            </div>
                        ))}
                      </div>
                  </div>
                  
              )}
                <div className = 'departure-form-buttons'>
                    <button type="button" 
                            onClick={handleAddTask} 
                            style = {{flex:1, borderTop:'1px solid white'}} 
                            className = 'globals--transparent-button'>
                        Add Task
                    </button>

                    <button type="submit" 
                            style = {{flex:1, justifyContent:'flex-end', borderTop:'1px solid white'}} 
                            className = 'globals--transparent-button'>
                        Submit
                    </button>
                </div>
              </div>
          
      </form>
  )
}
  

export default Dashboard;
