import { memo, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Pages/Main/Main';
import './styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Profile from './Pages/Profile/_Profile';
import { decrypt, notificationRoute } from './utils/routes';
import { VscDebugAlt } from "react-icons/vsc";
import GeoTable from './Pages/geotechnics/_geotechnical.repository';
import UserAccessControlPanel from './Pages/access.control.dashboard/_access.control.dashboard';
import Monitoring, {InsertMonitoringNode} from './Pages/monitoring/_monitoring';
import {
  ActionLogReferences,
  OverViewReferences,
  DescriptionBoxReferences,
  UserType,
  PageNumber,
  MonitoringReferences,
  HistoryLogItemProps
} from './functions_interfaces/interfaces';
import ActionLog from './Additional_Components/_ActionLog';
import Login from './Pages/Login/Login';
import { UserData, UserdataPropsToBeModified } from './functions_interfaces/interfaces';
import axios from 'axios';
import Cookies from 'js-cookie';
import UsersTable from './Pages/UsersTable/_users.table';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from './translations/en.json';
import slTranslation from './translations/sl.json';
import bsTranslation from './translations/bs.json';
import chTranslation from './translations/ch.json';


import { useTranslation } from 'react-i18next';
import VirtualTour, { InsertVirtualTourNode } from './Pages/virtualTour/_virtual.tours.styles';
import IfcRepository from './Pages/ifc.repository/ifc.repository';
import { IoMdNotifications, IoMdMenu  } from 'react-icons/io';
import { HistoryLogItem } from './Pages/Main/Components/_Overview';
import Dashboard from './Pages/prototype_vozni_red/vozni_red';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import Notes from './Pages/notes/notes';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    sl: { translation: slTranslation },
    bs: { translation: bsTranslation },
    ch: { translation: chTranslation },


    // Add more languages and translations here
  },
  lng: 'en', // Set the default language here
  interpolation: {
    escapeValue: false, // React already escapes strings
  },
});

axios.interceptors.request.use(config => {
  const token = Cookies.get('token');

  if (token) {
      config.headers['Authorization'] = token;
  }
  else {
      //alert("No token found at Cookies as interceptor!")
  }

  return config;
}, error => {
  return Promise.reject(error);
});

function App () {
/* This is the main APP => everything rendered starts here */

const mainTable = 'ProjectTable0'; // Main (MASTER) table name
const mainParentId = '13d7a15c-a3ac-11ed-a8fc-0242ac120002';  // Main (MASTER) table upper Parent

const { t } = useTranslation();
const { i18n } = useTranslation();

const DescriptionBoxReferences: DescriptionBoxReferences = {};
const actionLogReferences: ActionLogReferences = {updateLogString: null};
const OverViewReferences: OverViewReferences = {nodeEntryPoint: '', disableButton: () => {}};
const monitoringReferences: MonitoringReferences = {getModuleNodetreeMenu: null};
const VirtualTourReferences: any = {};


const [toggleMenu, setToggleMenu] = useState<boolean>(false);

let [auserData, setAUserData] = useState<any>( {
  email: '',
  token: Cookies.get('token'),
  firstname: '',
  lastname: '',
  telephone: '',
  uuid: '',
  companyuuid: '',
  usertype: undefined,
  language:'',
  page_access: [],
});
const [togglePage, setTogglePage] = useState(PageNumber.Login);
const [showActionLog, setShowActionLog] = useState(false);
const [showNotifications, setShowNotifications] = useState<boolean>(false);

useEffect(()=> {
  if(Cookies.get('token')) {
    loadUserDataFromToken()
  }
  else {
    //alert("No token in Cookies found for log-in to App!")
  }
}, []);
//----------------------------------------------------------------------------------------------------------------
function changeUserData(variable: UserdataPropsToBeModified, value: string) {
  const userData: UserData = auserData
  userData[variable] = value;
  setAUserData({
    companyuuid : userData.companyuuid ? userData.companyuuid : '',
    firstname : userData.firstname ? userData.firstname : '',
    telephone : userData.telephone ? userData.telephone : '',
    lastname : userData.lastname ? userData.lastname : '',
    email : userData.email ? userData.email : '',
    uuid : userData.uuid ? userData.uuid : '',
    usertype : userData.usertype !== undefined && userData.usertype !== null ? userData.usertype : null,
    language : userData.language? userData.language : ''
  })
};
//----------------------------------------------------------------------------------------------------------------

function router(register: boolean, login: boolean, userData?: UserData) {
  if (userData) {

    if (userData.language) {
      changeLanguage(userData.language);
    }/*
    setTogglePage(PageNumber.Home); */
    window.location.assign('/home')
  }
  else {
    setTogglePage(PageNumber.Login);
  }
};
//----------------------------------------------------------------------------------------------------------------

async function loadUserDataFromToken() {

  axios.post(decrypt, { token: Cookies.get('token') }).then(resp => {
    if (resp.data.resolved) {
      const companyuuid: string = resp.data.data.companyuuid;
      const firstname: string = resp.data.data.firstname;
      const telephone: string = resp.data.data.telephone;
      const lastname: string = resp.data.data.lastname;
      const email: string = resp.data.data.email;
      const uuid: string = resp.data.data.uuid;
      const usertype: number = resp.data.data.usertype;
      const language: string = resp.data.data.language;
      const page_access: [] = resp.data.data.page_access

      setAUserData({
        companyuuid : companyuuid ? companyuuid : '',
        firstname : firstname ? firstname : '',
        telephone : telephone ? telephone : '',
        lastname : lastname ? lastname : '',
        email : email ? email : '',
        uuid : uuid ? uuid : '',
        usertype : usertype !== undefined && usertype !== null ? usertype : null,
        language : language ? language : '',
        page_access: page_access? page_access : []
      })

      if (page_access) {
        console.log(`page_access: ${page_access}`);
      }
      else {
        alert("No page_access field in token!\n\nPlease log-in via username and password.");
      }

      if (language) {
        changeLanguage(language);
      }
      else {
        changeLanguage('en')
      }
      setTogglePage(PageNumber.Home)
    }
    else {
      alert("Error in loadUserDataFromToken()!")
    }
  })
  .catch(error => {
     alert(error);
  });
};
//----------------------------------------------------------------------------------------------------------------

function logout() {
  if (window.confirm(t('alerts.logout'))) {
    Cookies.remove('token', { path: '/', secure: true });
    setAUserData({
      token: '',
      email: '',
      firstname: '',
      telephone: '',
      lastname: '',
      companyuuid: '',
      uuid: '',
      usertype: UserType.Regular,
    });
    window.location.assign('/')
  }
}

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

//----------------------------------------------------------------------------------------------------------------

return (
    <div className = 'index-component-wrapper'>
      <Router>
      {togglePage === PageNumber.Login ? (
        <div className = 'index-component-wrapper-log-in'>
          <Login
            userData={auserData}
            changeUserData={changeUserData}
            router={router}
            setAuserData={setAUserData}
          />
        </div>
      ) : (
        <>
          <header className="index-component-wrapper-navbar">
              <button onClick={() => setToggleMenu(!toggleMenu)}
                      className = {'globals--transparent-button colors--white'}
                      style = {{fontSize:'25px'}}>
                  <IoMdMenu />
              </button>
            <div className={`index-component-wrapper-dropdown-menu  ${toggleMenu?'openMenu': ''}`}
                  onMouseLeave={() => setToggleMenu(false)}>

              <div className={`index-component-wrapper-dropdown-menu-title`}>
                Meni
              </div>
              <button>
                <Link to = '/home'>
                  {t(`index.head.dropdown.home`)}
                </Link>
              </button>
              {auserData?.page_access
                ?.slice()
                ?.sort((a, b) =>
                  t(`index.head.dropdown.${a.page_name}`).localeCompare(t(`index.head.dropdown.${b.page_name}`))
                )
                ?.map((item) => (
                  <button onClick = {() => console.log(item.page_name)}>
                    <Link key={item.page_name} to={`/${item.page_name}`}>
                      {t(`index.head.dropdown.${item.page_name}`)}
                    </Link>
                  </button>
                ))
              }

            </div>

            <div className="index-component-wrapper-navbar-title-text">

              <img  className="index-component-wrapper-navbar-title-logo"
                    src={'./logo_IRGO_ICON.svg'}
                    alt="My SVG"/>

              <div className="index-component-wrapper-navbar-title-text"
                    onClick={() => setToggleMenu(!toggleMenu)}>
                Portal IRGO
              </div>

            </div>{/*
            <div>
              {Icons.arrowLeft} Click Logo for Menu
            </div> */}

            <div className="index-component-wrapper-navbar-utility-menu">

              <button className="globals--transparent-button colors--white mobile-hide"
                      onClick={() => setShowNotifications(!showNotifications)}>

                <IoMdNotifications/>
              </button>

              <button className="globals--transparent-button colors--white" /*
                      onClick={() => setTogglePage(PageNumber.Profile)} */>

                    <Link className="profile-link" key={PageNumber.Profile} to={`/profile`} style = {{textDecoration:'none', color:'#bcf124'}}>
                      {`${auserData.lastname} ${auserData.firstname[0]}.`}
                    </Link>
                <strong className="profile-link"></strong>
              </button>

              <button onClick={() => logout()}
                      className="globals--transparent-button colors--white">
                {t('index.head.logout')}
              </button>

              <button className="globals--transparent-button colors--white mobile-hide"
                      onClick={() => setShowActionLog(!showActionLog)}>
                <VscDebugAlt/>
              </button>

              <div style = {{display: showActionLog? '': 'none'}}>
                <ActionLog actionLogReferences={actionLogReferences} />
              </div>

            </div>
          </header>
          {showNotifications &&
            <div className = 'resizable-popup'>
                <div style = {{position:'sticky',padding: '0 1rem', top: 0,color: 'white', backgroundColor:'rgba(0, 52, 0, 0.76)', margin:0, textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <h1>Notifications</h1>
                  <button className = 'globals--close-button' onClick={() => setShowNotifications(false)}/>{/*
                  <div style = {{alignContent:'center'}}>
                    <span style = {{alignContent:'center'}}>Označi vse kot prebrano:</span>
                    <input type = 'checkbox'/>
                  </div> */}
                </div>
              <Notifications/>
            </div>
          }
          <main className='index-component-wrapper-main-content'>
                <Routes>
                  <Route path="/main" element={
                    <Main
                      selectTable={mainTable}
                      recursiveParent={mainParentId}
                      DescriptionBoxReferences={DescriptionBoxReferences}
                      actionLogReferences={actionLogReferences}
                      OverViewReferences={OverViewReferences}
                      key="Main-Library"
                    />
                  } />
                  <Route path="/users" element={
                    <UsersTable
                      actionLogReferences={actionLogReferences}
                      key="users"
                    />
                  } />
                  <Route path="/access_table" element={
                    <UserAccessControlPanel actionLogReferences={actionLogReferences} />
                  } />
                  <Route path="/profile" element={
                    <Profile
                      userData={auserData}
                      actionLogReferences={actionLogReferences}
                      loadUserDataFromToken={loadUserDataFromToken}
                    />
                  } />
                  <Route path="/geotechnics" element={
                    <GeoTable actionLogReferences={actionLogReferences} />
                  } />
                  <Route path="/ifc_repository" element={
                    <IfcRepository VirtualTourReferences={VirtualTourReferences}/>
                  } />
                  <Route path="/monitoring" element={
                    <Monitoring
                      monitoringReferences={monitoringReferences}
                      actionLogReferences={actionLogReferences}
                    />
                  } />
                  <Route path="/home" element={
                    <></>

                  } />
                  <Route path="/docs_matm" element={<Notes/>} />

                  <Route path="/DepartureBoard" element={
                     <Dashboard actionLogReferences={actionLogReferences} PageAccess = {auserData.page_access}/>
                  } />

                  <Route path="/qgis_repository" element={<span>Coming soon...</span>} />
                  <Route path="/virtualtour" element={
                    <VirtualTour VirtualTourReferences={VirtualTourReferences} />
                  } />
                  <Route path="*" element={<Navigate to="/home" />} />
                </Routes>

          </main>
        </>
      )}
      </Router>
    </div>
  );
};

export const Notifications: React.FC = () => {

  const [notifications, setNotifications] = useState<HistoryLogItemProps[]>([]);

  useEffect(() => {
    fetchUsersNotifications();
  }, [])

  const fetchUsersNotifications = async() => {
    try {
      let response = await axios.get(notificationRoute);

      if ( response && response.data.resolved ) {
        console.log(response.data.data);
        setNotifications(response.data.data);
      }
      else if ( response && response.data.resolved === false) {
        alert(response.data.message);
      }
      else {
        alert("Cannot fetch userNotifications!");
      }
    }
    catch (error) {
      console.log(error);
      alert(error);
    }
  }

  return (
<div style = {{overflowY:'auto', flex: '1'}}>

  {notifications?.sort((a, b) => {
    // Convert date strings to Date objects and then to time in milliseconds
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA;
  })
  .map((item: any) => (
    <HistoryLogItem
      key={item.id} // Added a key here for proper list rendering
      changeOwner={item.changeOwner}
      changeDescription={item.changeDescription}
      date={item.date}
      newStatus={item.newStatus}
      statusChange={item.statusChange}
    />
  ))}
</div>
  )
}
export default memo(App);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
